<template>
  <b-button
    title="Current location"
    variant="primary"
    @click="getMyLocation"
  >
    <b-icon-cursor />
  </b-button>
</template>
<script>
import EventBus from '@/eventBus';

import { mapActions, mapGetters } from 'vuex';
import Constants from '@/store/storeConstants';

import {
  BButton,
  BIconCursor,
} from 'bootstrap-vue';

const {
  ACTIONS,
  GETTERS,
  ReverseGeocodeStore: RGS,
} = Constants;

export default {
  components: {
    BButton,
    BIconCursor,
  },
  computed: {
    ...mapGetters({
      ReverseGeocodeResult: `${RGS.Name}/${GETTERS.DATA}`,
    }),
  },
  methods: {
    ...mapActions({
      ReverseGeocode: `${RGS.Name}/${ACTIONS.LOAD}`,
    }),
    getMyLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.myLocation = {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        };
        this.ReverseGeocode(this.myLocation);
        EventBus.$emit('coordinateSet', this.myLocation);
      });
    },
  },
  data() {
    return {
      myLocation: {
        latitude: null,
        longitude: null,
      },
    };
  },
  name: 'MyLocation',
};
</script>
