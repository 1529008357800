<template>
  <div class="map-container" :id="leafletContainerId">Loading Map...</div>
</template>

<script>
import EventBus from '@/eventBus';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';
import 'leaflet-draw/dist/leaflet.draw';
import cssVars from 'css-vars-ponyfill/dist/css-vars-ponyfill';
import { mapActions, mapGetters } from 'vuex';
import Constants from '@/store/storeConstants';

const { ACTIONS, GETTERS, ReverseGeocodeStore: RGS } = Constants;
const markerIconUrl = require('@/assets/pin/bluePoint_01_dark_32.png');

L.Marker.prototype.options.icon = L.icon({
  iconAnchor: [16, 32],
  iconSize: [32, 32],
  iconUrl: markerIconUrl,
  shadowAnchor: [5, 43],
});

export default {
  computed: {
    ...mapGetters({
      ReverseGeocodeResult: `${RGS.Name}/${GETTERS.DATA}`,
      ErrorMessage: `${RGS.Name}/${RGS.Getters.ErrorMessage}`,
    }),

    baseType: () => 'vector-green',
    countryCenter: () => [-28.4792625, 24.6727135],
    countryBoundingBox: () => [
      [-34.8191663551, 16.3449768409],
      [-22.0913127581, 32.830120477],
    ],
    leafletContainerId: () => 'leaflet-container-id',
    markerIcon() {
      return !L
        ? {}
        : L.icon({
          iconAnchor: new L.Point(16, 32),
          iconUrl: markerIconUrl,
        });
    },
  },
  data() {
    return {
      agMapLoaded: false,
      leafletMap: null,
      marker: null,
    };
  },
  methods: {
    ...mapActions({
      ReverseGeocode: `${RGS.Name}/${ACTIONS.LOAD}`,
    }),

    drawMaker(lat, lng) {
      if (this.marker) {
        this.leafletMap.removeLayer(this.marker);
      }
      this.marker = L.marker([lat, lng]).addTo(this.leafletMap);
      const latLngBounds = new L.LatLngBounds();
      latLngBounds.extend(this.marker.getLatLng());
      this.leafletMap.fitBounds(latLngBounds);
    },
    checkIfAgMapLoaded() {
      this.agMapLoaded = !!window.AfriGIS;
      if (this.agMapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfAgMapLoaded();
      }, 500);
    },
    initLeafletDraw() {
      const options = {
        draw: {
          circle: false,
          circlemarker: false,
          rectangle: false,
          polyline: false,
          polygon: false,
          marker: {
            icon: this.markerIcon,
            repeatMode: false,
          },
        },
      };
      const layerInfoLeafletDrawControl = new L.Control.Draw(options);
      this.leafletMap.addControl(layerInfoLeafletDrawControl);
      this.leafletMap.on(L.Draw.Event.CREATED, (e) => {
        const { lat, lng } = e.layer.getLatLng();
        this.drawMaker(lat, lng);
        const latLon = { lat, lon: lng };
        this.ReverseGeocode(latLon);
      });
    },
    initMap() {
      if (this.leafletMap) {
        return;
      }
      this.leafletMap = window.AfriGIS.map(this.leafletContainerId, {
        center: L.latLng(this.countryCenter),
        authkey: process.env.VUE_APP_AG_LEAFLET_API_KEY,
        mapType: this.baseType,
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: 18,
        minZoom: 3,
        zoom: 6,
        zoomControl: true,
      });

      this.leafletMap.fitBounds(this.countryBoundingBox);
      this.leafletMap.zoomControl.setPosition('bottomright');
      L.drawLocal.draw.toolbar.buttons.marker = 'Place pin on location for address';
      this.initLeafletDraw();
    },

    formatPopupContent(address) {
      return address.formattedAddress;
    },
    onCoordinateSet(val) {
      this.drawMaker(val.lat, val.lon);
    },
    openPopup(popupContent) {
      const popupOptions = {
        autoPanPaddingTopLeft: L.point(0, 0),
        offset: [0, -20],
      };
      this.marker.bindPopup(`${popupContent}`, popupOptions).openPopup();
    },
  },

  destroyed() {
    EventBus.$off('coordinateSet');
  },

  mounted() {
    this.checkIfAgMapLoaded();
    cssVars();
    EventBus.$on('coordinateSet', this.onCoordinateSet);
  },
  name: 'LeafletMap',

  watch: {
    agMapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
    ErrorMessage(newV) {
      if (!newV) {
        return;
      }
      this.openPopup(newV);
    },
    ReverseGeocodeResult(newV) {
      if (!newV) {
        return;
      }
      const formattedPopupContent = this.formatPopupContent(newV);
      this.openPopup(formattedPopupContent);
    },
  },
};
</script>

<style src="leaflet-draw/dist/leaflet.draw.css"></style>
<style>
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.leaflet-draw-section a {
    background-color: #addbe5!important;
    color: #fff !important;
}
.leaflet-draw-actions a {
    background-color: var(--primary) !important;
    color: #fff !important;
}
.leaflet-bar.leaflet-control-zoom a {
  background-color: var(--primary) !important;
  color: #fff !important;
}
.leaflet-draw-toolbar a {
  background-image: url("https://unpkg.com/leaflet-draw@1.0.2/dist/images/spritesheet.svg") !important;
}
@media screen and (max-width: 767px) {
  .leaflet-draw.leaflet-control {
    top: 150px;
  }
}
@media screen and (min-width: 768px) {
  .leaflet-draw.leaflet-control {
    top: 150px;
  }
}
@media (min-width: 992px) {
  .leaflet-draw.leaflet-control {
    top: 120px;
  }
}
@media (min-width: 1200px) {
  .leaflet-draw.leaflet-control {
    top: 100px;
  }
}
</style>
