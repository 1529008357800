<template>
  <div>
    <div class="input-group">
      <input
        name="latitude"
        placeholder="latitude"
        v-model="latitudeData"
        class="form-control"
        v-validate="{ required: true, decimal: true, between: [-90, 90] }"
      />
      <input
        name="longitude"
        placeholder="longitude"
        v-model="longitudeData"
        class="form-control"
        v-validate="{ required: true, decimal: true, between: [-180, 180] }"
      />
      <button
        class="btn btn-primary"
        @click="reverseGeocode"
        :disabled="errors.any() || isDisabledSubmit"
      >
        Search
      </button>
    </div>
    <p class="coordinate-text-danger">{{ errors.first("latitude") }} </p>
    <p class="coordinate-text-danger">{{ errors.first("longitude") }}</p>
    <div class="bg-primary mt-2 text-white text-center">
      <p class="mb-0">Results for this demo are returned to Suburb level.</p>
      <p>
        <a target="_blank" class="card-link" href="/contact-us">
          <span class="bookExpert">Contact Sales</span>
        </a> for Street Address level results.</p>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import Constants from '@/store/storeConstants';

const { ACTIONS, GETTERS, ReverseGeocodeStore: RGS } = Constants;

export default {
  computed: {
    ...mapGetters({
      ReverseGeocodeResult: `${RGS.Name}/${GETTERS.DATA}`,
    }),
    isDisabledSubmit() {
      return !this.latitudeData || !this.longitudeData;
    },
  },
  data() {
    return {
      latitudeData: null,
      longitudeData: null,
    };
  },
  methods: {
    ...mapActions({
      ReverseGeocode: `${RGS.Name}/${ACTIONS.LOAD}`,
    }),
    async reverseGeocode() {
      if (!this.validateLat(this.latitudeData) || !this.validateLng(this.longitudeData)) {
        return;
      }
      const latLon = { lat: this.latitudeData, lon: this.longitudeData };
      EventBus.$emit('coordinateSet', latLon);
      await this.ReverseGeocode(latLon);
    },
    validateLat(value) {
      if (!value) {
        return false;
      }
      return value >= -90 && value <= 90;
    },
    validateLng(value) {
      if (!value) {
        return false;
      }
      return value >= -180 && value <= 180;
    },
  },
  name: 'XySearchBox',
  props: {
    latitude: {
      type: Number,
      default: null,
      validator(value) {
        return value >= -90 && value <= 90;
      },
    },
    longitude: {
      type: Number,
      default: null,
      validator(value) {
        return value >= -180 && value <= 180;
      },
    },
  },
};
</script>

<style scoped>
.pointer-events-all{
  pointer-events: all;
}
.coordinate-text-danger {
  background-color: #ed1c24;
  color: #fff !important;
}
</style>
