import Axios from 'axios';
import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';
import Constants from '@/store/storeConstants';

const STATEVAR_KEY = 'key';
const STATEVAR_TOKEN = 'token';

const {
  ACTIONS,
  MUTATIONS,
  ReverseGeocodeStore: RGS,
  STATE_VARS,
} = Constants;

const ENDPOINT = `${process.env.VUE_APP_ROOT_API}/proxies/search/api/ReverseGeocode`;

const STATE_PROPS_TO_EXPOSE = [
  STATE_VARS.DATA,
  STATE_VARS.IS_LOADING,
  RGS.StateVars.ErrorMessage,
];

const initialState = () => ({
  [STATE_VARS.DATA]: null,
  [STATE_VARS.IS_LOADING]: false,
  [STATEVAR_KEY]: null,
  [STATEVAR_TOKEN]: null,
  [RGS.StateVars.ErrorMessage]: null,
});

const state = initialState();

const actions = {
  [ACTIONS.LOAD]: async (context, payload) => {
    context.commit(MUTATIONS.SET_DATA, null);
    context.commit(MUTATIONS.SET_IS_LOADING, true);

    try {
      const request = `${ENDPOINT}?latitude=${payload.lat}&longitude=${payload.lon}`;
      const { data: { code, message, result } } = await Axios.get(request);

      if (code !== 200) {
        throw new Error(message);
      }
      if (!result) {
        context.commit(RGS.Mutations.SetErrorMessage, 'No results available for this point');
        return;
      }
      context.commit(MUTATIONS.SET_DATA, result);
    } catch (error) {
      throw new Error(`Could not load revese geocode data - '${error}'`);
    } finally {
      context.commit(MUTATIONS.SET_IS_LOADING, false);
    }
  },
};

const getters = {
  ...GettersUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
};

const mutations = {
  ...MutationsUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
};

export default {
  actions,
  getters,
  mutations,
  state,
};
