<template>
  <div
    id="trip-cost-description"
  >
    <b-card
      id="trust-us-card"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
      text-variant="white"
    >
      <div class="content">
        <h5>Trust us for</h5>
        <ul>
          <li>SABS and ISO compliant Address data.</li>
          <li>Accurate and verified address results returned.</li>
          <li>
            Components available via API to integrate into your own solutions.
          </li>
          <li>Over 50 Million searchable address combinations.</li>
        </ul>
      </div>
    </b-card>
    <b-card
      id="possible-uses-card"
    >
      <h5>Possible uses:</h5>
      <ul>
        <li>Find an address via coordinates.</li>
        <li>Get an address for a location by clicking on a map.</li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';

const backgroundImage = require('@/assets/info_bg.png');

export default {
  components: {
    BCard,
  },
  computed: {
    backgroundImage: () => backgroundImage,
  },
  name: 'Information',
};
</script>

<style scoped>
#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom:0;

  border-color: white;
}
#trust-us-card .content{
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

#possible-uses-card {
  border-top: 0;
  border-color: white;
}
</style>
