<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1>Coordinate Search</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          @click="shareViaWebShare"
          title="Share This App"
          variant="outline-secondary"
        >
          <b-icon-share />
        </b-button>
        <b-modal
          id="modal-1"
          title="Share This App"
          ref="fallback-modal"
          @ok="handleOk"
        >
          <b-form-textarea
            id="share-app-fallback"
            ref="toCopy"
            v-model="shareUrl"
            rows="1"
            max-rows="6"
          />
          <template #modal-footer="{ ok }">
            <b-button
              size="sm"
              variant="primary"
              @click="ok()"
            >
              Copy URL to Clipboard
            </b-button>
          </template>
        </b-modal>
        <b-button
          @click="startTour"
          variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <contact-expert />
    <v-tour
      class="location-tour"
      name="locationTour"
      :options="tourOptions"
      :steps="locationTourSteps"
    />
    <b-row>
      <b-col class="mb-3" lg="8">
        <b-row class="top-control-row pt-2 px-2 no-gutters">
          <xy-search-box id="coordinateSearch" class="enable-pointers"/>
          <b-col class="map-controls ml-1">
            <my-location id="myLocation" class="enable-pointers myLocation"/>
          </b-col>
        </b-row>
        <leaflet-map class="leaflet-map"/>
      </b-col>
      <b-col class="mb-5" lg="4">
        <information-section />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BButton,
  BCol,
  BContainer,
  BFormTextarea,
  BIconShare,
  BRow,
} from 'bootstrap-vue';

import ContactExpert from '@/components/ContactExpert.vue';
import InformationSection from '@/components/InformationSection.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import XySearchBox from '@/components/XySearchBox.vue';
import MyLocation from '../components/MyLocation.vue';

export default {
  components: {
    BButton,
    BCol,
    BContainer,
    BFormTextarea,
    BIconShare,
    BRow,
    ContactExpert,
    InformationSection,
    LeafletMap,
    MyLocation,
    XySearchBox,
  },
  computed: {
    shareUrl() {
      const { origin, pathname } = window.location;
      return `${origin}${pathname}`;
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: false,
      };
    },
    locationTourSteps() {
      return [
        {
          content:
            'Type in the coordinate you would like the address for.',
          header: {
            title: 'Coordinate Search',
          },
          params: {
            placement: 'auto',
          },
          target: '#coordinateSearch',
        },
        {
          content:
            'Use the browser location feature to find your coordinate as well as the address.',
          header: {
            title: 'Current Location',
          },
          params: {
            placement: 'auto',
          },
          target: '#myLocation',
        },
        {
          content:
            'Use the Drop Pin feature by clicking anywhere on the map to find the coordinates of the location as well as the address.',
          header: {
            title: 'Drop a Pin',
          },
          params: {
            placement: 'auto',
          },
          target: '.leaflet-draw-draw-marker',
        },
        {
          content:
            'Reverse Geocode Results will be shown on the map.',
          header: {
            title: 'Reverse Geocode Result',
          },
          params: {
            placement: 'auto',
          },
          target: '.leaflet-map',
        },
      ];
    },
  },
  destroyed() {
    window.removeEventListener('iris:share-capability');
    window.removeEventListener('iris:start-tour');
  },
  methods: {
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    startTour() {
      this.$tours.locationTour.start();
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenLocationTour'),
      );
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenLocationTour', true);
      }
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: this.shareUrl,
          })
          .then()
          .catch(() => {
            // Handle the error!
          });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
  },
  mounted() {
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    this.tourConfigure();
  },
};
</script>
<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
.leaflet-map {
  position: relative;
  width: 100%;
  height: 75vh;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
.location-tour {
  z-index: 500;
}
.map-controllers {
  position: absolute;
  z-index: 500;
  widows: 100%;
}

.enable-pointers {
  pointer-events: auto;
}

.top-control-row {
  position: absolute;
  top: 0;
  width: 92%;
  z-index: 450;
  pointer-events: none;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
  .myLocation {
    float: right!important;
  }
}
</style>
